#body-overlay {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 3;
  top: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
}

.real-menu {
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1031;
  width: 300px;
  height: 100%;
  padding: 0.5rem 1rem;
  -webkit-box-shadow: 0 6px 12px rgba(107, 82, 82, 0.3);
  box-shadow: 0 6px 12px rgba(107, 82, 82, 0.3);
  background-color: #262626;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: ease 0.2s all;
  transition: ease 0.2s all;
  color: white;
}

body.menu-open #body-overlay {
  display: block;
}

body.menu-open .real-menu {
  left: 0;
}

html,
body {
  overflow-x: hidden !important;
  max-width: 100% !important;
  font-size: 18px;
}

@media (max-width: 330px) {
  html,
  body {
    font-size: 15px;
  }
}

@media (max-width: 290px) {
  html,
  body {
    font-size: 13px;
  }
}

body {
  background-color: black;
  color: white !important;
}

.shopping-cart-link {
  position: relative;
}

.shopping-cart-link .cart-item-count {
  position: absolute;
  right: 35px;
}

@media (max-width: 769px) {
  .shopping-cart-link .cart-item-count {
    right: 3px;
  }
}

.navbar {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.dropdown-menu {
  background: #262626;
}

/* color change here */
@media (max-width: 769px) {
  .dropdown-menu {
    background: #0C0E17;
  }
}

.dropdown-menu li a {
  color: white;
}

.dropdown-menu li a:hover {
  background: #0C0E17;
  color: white;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

.btn {
  text-transform: capitalize;
}

.btn-primary {
  background-color: #0C0E17;
  border-color: #0C0E17;
}

.btn-primary:hover {
  background-color: #0C0E17;
  border-color: #0C0E17;
}

.btn-primary:active {
  background-color: #0C0E17;
  border-color: #0C0E17;
}

.btn-primary:focus {
  background-color: #0C0E17;
  border-color: #0C0E17;
}

.btn-default {
  background-color: transparent;
  border-color: transparent;
  color: white;
}

.btn-default:hover {
  background-color: transparent;
  border-color: transparent;
  color: white;
}

.btn-default:active {
  background-color: transparent;
  border-color: transparent;
}

.btn-default:focus {
  background-color: transparent;
  border-color: transparent;
}

.btn-outline-primary {
  color: white;
  border-color: #0C0E17;
}

.btn-outline-primary:hover {
  border-color: #0C0E17;
  color: white;
  background-color: transparent;
}

.btn-outline-primary:active {
  border-color: #0C0E17;
  color: white;
}

.btn-outline-primary:focus {
  border-color: #0C0E17;
  color: white;
}

.top-search-bar {
  width: 500px;
}

.top-search-bar input {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  background-color: transparent !important;
  border: solid 1px white !important;
  border-right: none !important;
  color: white !important;
}

.top-search-bar input:focus {
  background-color: #1a1a1a;
}

.top-search-bar button {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-left: none;
}

.top-search-bar button:hover {
  background-color: #1a1a1a;
  border: 1px solid #ced4da;
  border-left: none;
}

.top-search-bar button:focus {
  background-color: #1a1a1a;
}

.text-primary {
  color: #0C0E17 !important;
}

.bg-dark {
  background-color: #1a1a1a !important;
}

.homepage-header {
  min-height: 80vh;
  position: relative;
}

@media (max-width: 769px) {
  .homepage-header {
    min-height: 60vh;
  }
}

.homepage-header #hero-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -2;
}

.homepage-header #myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

.homepage-header #myBtn:hover {
  background: #ddd;
  color: black;
}

.homepage-header .hero-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(16%, black),
    color-stop(39%, rgba(0, 0, 0, 0.496534))
  );
  background: linear-gradient(360deg, black 16%, rgba(0, 0, 0, 0.496534) 39%);
}

.border-left-primary {
  border-left: 3px #0C0E17 solid;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  color: white;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.main-nav {
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.main-nav:hover {
  background-color: #0C0E17;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.main-nav.active {
  background-color: #262626;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  color: white;
  border-color: transparent;
  border-bottom: 2px solid #0C0E17;
}

.movie-thumb {
  width: 157px;
  height: 200px;
  border-radius: 0px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  /* Animation */
  background-color: transparent;
  border-radius: 10px;
}

.movie-thumb .movie-thumb-cover {
  width: 150px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
}

.movie-thumb .movie-thumb-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 769px) {
  .movie-thumb {
    width: 150px;
  }
}

.movie-thumb .movie-thumb-video {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.movie-thumb .movie-thumb-content {
  padding: 5px;
  background-color: #262626;
  display: none;
  position: absolute;
  width: inherit;
  margin-top: -5px;
}

.movie-thumb .movie-thumb-content ul {
  font-size: 3px;
  margin: 0;
}

.movie-thumb .movie-thumb-content h5 {
  font-size: 8px;
}

.movie-thumb .movie-thumb-content p {
  font-size: 8px;
  margin: 0;
}

.movie-thumb .movie-thumb-content button {
  font-size: 7px;
}

.z-index-1 {
  z-index: 1 !important;
}

.movie-thumb.zoom {
  -webkit-transform: translate(0%) scale(2);
  transform: translate(0%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-left {
  -webkit-transform: translate(50%) scale(2);
  transform: translate(50%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-right {
  -webkit-transform: translate(-50%) scale(2);
  transform: translate(-50%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-extreme-right {
  -webkit-transform: translate(-100%) scale(2);
  transform: translate(-100%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-extreme-right-mobile {
  -webkit-transform: translate(-140%) scale(2);
  transform: translate(-140%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-extreme-left-mobile {
  -webkit-transform: translate(150%) scale(2);
  transform: translate(150%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.movie-thumb.zoom-extreme-left {
  -webkit-transform: translate(150%) scale(2);
  transform: translate(150%) scale(2);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* Animation */
  font-size: 10px !important;
}

.slick-list .slick-track {
  width: inherit !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bg-black {
  background: #1a1a1a;
}

.bg-light-black {
  background: #262626;
}

.movie-thumb-list {
  position: relative;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  /* Animation */
  width: auto !important;
  margin-right: 5px;
  cursor: pointer;
}

.movie-thumb-list.overlay {
  z-index: 20000000 !important;
}

.no-decoration {
  text-decoration: none !important;
}

.underline {
  position: relative;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.underline::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  border-bottom: 3px solid #0C0E17;
  width: 100%;
}

.accordion-button:not(.collapsed) {
  color: #0C0E17;
}

.best-pick-cover {
  position: relative;
  width: 100%;
}

@media (min-width: 679px) {
  .best-pick-cover {
    width: 550px;
  }
}

.best-pick-cover .best-pick-text {
  position: absolute;
  width: 400px;
  height: 230px;
  right: -150px;
  bottom: -70px;
}

@media (max-width: 769px) {
  .best-pick-cover .best-pick-text {
    position: absolute;
    width: 80%;
    left: 10%;
    bottom: -150px;
    height: 250px;
  }
}

.best-pick-cover .best-pick-text .best-pick-text-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 769px) {
  .best-pick-cover .best-pick-text .best-pick-text-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ellipsis-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 769px) {
  .best-pick-col-2 {
    margin-top: 180px;
  }
}

.faqs .accordion-button {
  background-color: #262626;
  color: white;
}

.faqs .accordion-button:not(.collapsed) {
  color: #0C0E17;
}

footer {
  background-color: #262626;
}

.slick-dots {
  list-style: none;
}

.slick-dots li {
  margin-bottom: 5px;
}

@media (max-width: 769px) {
  .slick-dots li {
    margin-bottom: 0px;
  }
}

.slick-dots li button {
  background-color: white;
  border-radius: 100%;
  height: 25px;
  color: white;
  width: 25px;
  border: none;
  font-size: 0;
}

@media (max-width: 769px) {
  .slick-dots li button {
    height: 15px;
    width: 15px;
  }
}

.slick-dots .slick-active button {
  background-color: #0C0E17;
  color: #0C0E17;
}

.tab-pane {
  position: relative;
}

.tab-pane #featured-dots,
.tab-pane #soon-dots,
.tab-pane #award-dots {
  position: absolute;
  top: 60px;
  right: -60px;
}

@media (max-width: 769px) {
  .tab-pane #featured-dots,
  .tab-pane #soon-dots,
  .tab-pane #award-dots {
    top: 20px;
    right: 0px;
  }
}

.full-slide-dots {
  position: absolute;
  margin-top: 100px;
  right: -60px;
}

@media (max-width: 769px) {
  .full-slide-dots {
    display: none;
  }
}

.full-slide-dots .slick-dots {
  padding-right: 10px;
}

.side-menu .side-menu-link {
  text-decoration: none !important;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.side-menu .side-menu-link .side-menu-icon {
  width: 20px;
}

.bg-primary {
  background: #0C0E17 !important;
}

.movie-details-modal .modal-dialog {
  max-width: 400px;
}

.movie-details-modal .modal-details-image-box {
  width: 220px;
  height: 300px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}

.movie-details-modal .modal-details-image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.movie-details-modal .modal-body {
  background: #262626;
}

.auth {
  min-height: 100vh;
}

.auth .auth-row {
  min-height: inherit;
}

.auth-row1 {
  /* background-image: url(../images/auth-bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.auth-row1 .overlay {
  background: #00000070;
  width: 100%;
  height: 100%;
}

.auth-row1 .auth-row1-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cursor {
  cursor: pointer;
}

.input-group input {
  border: 1px;
  border-right: none;
  background: #ffff;
}

.input-group .input-group-text {
  background-color: #ffff;
  border: 1px solid #ffff;
  margin-left: 0;
}

.password-progress {
  height: 0.15rem;
}

.form-check-input:checked {
  background-color: #0C0E17;
  border-color: #0C0E17;
}

.collapse-css-transition {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

.mySpinner {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

/*# sourceMappingURL=style.css.map */
