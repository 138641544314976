.sidebar,
.sidebar-content {
  background-color: #0C0E17;
}

.sidebar-link,
a.sidebar-link {
  background-color: #0C0E17;
}

.navbar-bg {
  background-color: #0C0E17;
}

.sidebar-link:hover {
  background: #0C0E17;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active > .sidebar-link {
  background-color: #0C0E17;
}

.bg-dark-purple {
  background-color: #0C0E17;
}
/*# sourceMappingURL=admin.css.map */
